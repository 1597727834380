import React, { FC, ReactElement, useRef } from 'react';

import { useClickableLinks } from 'hooks';

import { DescriptionProps } from './StreamScreen-Description.interface';
import { cnStreamScreen } from '../StreamScreen.cn';

import './StreamScreen-Description.css';

export const Description: FC<DescriptionProps> = (props: DescriptionProps): ReactElement => {
    const {
        description,
        platform,
    } = props;

    const ref = useRef<HTMLDivElement>();

    useClickableLinks({
        ref,
        text: description,
    });

    if (description && platform === 'desktop') {
        return (
            <div
                className={cnStreamScreen('Description')}
                ref={ref}
            />
        );
    }

    return null;
};
