import { push } from 'connected-react-router';
import { compose, composeU } from '@bem-react/core';
import React, { FC, useState, useEffect, useCallback, ReactElement, useMemo, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Language } from '@yandex-int/i18n';

import {
    selectStreamDetails,
    requestStreamDetails,
    streamEndEvent,
    flushStreamDetails,
    flushStreamError,
} from 'ducks/stream';
import {
    hideYandexLamp,
    showYandexLamp,
} from 'ducks/yandexLamp';
import { hideYandexBug, setParamsYandexBug, showYandexBug } from 'ducks/yandexBug';
import {
    showFooter,
    hideFooter,
} from 'ducks/footer';
import { selectCurrentUser } from 'ducks/user';
import {
    selectPlayerType,
    setPlayerType,
} from 'ducks/streamPlayer';

import {
    usePlatformContext,
    useTitle,
} from 'hooks';

import { ViewersSocket } from 'components/ViewersSocket';

import { useStreamLanguage } from 'hooks/useStreamLanguage';

import { useLeaveHandlers } from 'hooks';
import { withTypeEther } from './_type/StreamScreen_type_ether';
import { withLoading } from './_loading/StreamScreen_loading';
import { withEmpty } from './_empty/StreamScreen_empty';
import { withError } from './_error/StreamScreen_error';
import { StreamScreenContainerMatchParams } from './StreamScreen.interface';
import { i18nStreamScreen } from './StreamScreen';
import { StreamScreenMobile as StreamScreenMobileBase } from './StreamScreen@mobile';
import { StreamScreenDesktop as StreamScreenDesktopBase } from './StreamScreen@desktop';

const StreamScreenMobile = compose(
    withEmpty,
    withError,
    withLoading,
    composeU(
        withTypeEther,
    ),
)(StreamScreenMobileBase);

const StreamScreenDesktop = compose(
    withEmpty,
    withError,
    withLoading,
    composeU(
        withTypeEther,
    ),
)(StreamScreenDesktopBase);

export const StreamScreen: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const playerType = useSelector(selectPlayerType);
    const { environment } = useSelector(selectCurrentUser);
    const {
        responseError,
        name,
        streamDescription,
        streamId,
        chatInviteHash,
        isRequesting,
        retranslatorHost,
        etherUrl,
        etherBackId,
        eventId,
        id,
        uri,
        stopTime,
        event,
        translatedStreams,
        lang,
        viewersCount: initialViewersCount,
    } = useSelector(selectStreamDetails);

    const match = useRouteMatch<StreamScreenContainerMatchParams>();

    const platform = usePlatformContext();
    const StreamScreenView = platform === 'mobile' ? StreamScreenMobile : StreamScreenDesktop;

    const callId = match.params.id;

    const calendarEventUrl = new URL('https://calendar.yandex-team.ru/event/');
    calendarEventUrl.searchParams.set('event_id', `${eventId}`);

    const [viewersCount, setViewersCount] = useState<number>(null);

    const [isFirstMount, setIsFirstMount] = useState<boolean>(true);

    const [streamLangSetting, setStreamLangSetting] = useStreamLanguage();

    useEffect((): FunctionVoid => {
        if (responseError) {
            return;
        }

        if (!etherBackId) {
            return;
        }

        // Проверка языка трансляции
        // Бэкенд присылает все доступные языки трансляции и id звонков для каждого языка
        // Проверем, что установленный пользовтелем язык трансляции соответствует языку текущей трансляции
        // Если нет, то редиректим трансляцию с установленным языком, если такая существует
        if (translatedStreams && streamLangSetting !== lang && translatedStreams[streamLangSetting]) {
            const streamId = translatedStreams[streamLangSetting];

            dispatch(flushStreamDetails());
            dispatch(flushStreamError());
            dispatch(push(`/streams/${streamId}`));

            return;
        }
    }, [dispatch, etherBackId, responseError, lang, streamLangSetting, translatedStreams]);

    const navigateToChat = useCallback((): void => {
        dispatch(push(`/streams/${id}/chat`));
    }, [dispatch, id]);

    const handlePlayerTypeChange = useCallback((e): void => {
        dispatch(setPlayerType(e.target.value));
    }, [dispatch]);

    const title = useMemo(() => {
        const parts = [i18nStreamScreen('stream-title')];

        if (responseError) {
            parts.unshift(i18nStreamScreen('stream-error'));
        }

        if (name) {
            parts.unshift(`${name}`);
        }

        return parts;
    }, [name, responseError]);

    useTitle(title);

    useEffect((): void => {
        setIsFirstMount(false);
    }, []);

    useEffect((): FunctionVoid => {
        if (platform === 'mobile') {
            dispatch(hideYandexBug());
            dispatch(hideYandexLamp());
            dispatch(hideFooter());
        }

        return () => {
            if (platform === 'mobile') {
                dispatch(showYandexBug());
                dispatch(showYandexLamp());
                dispatch(showFooter());
            }
        };
    }, [platform, dispatch]);

    useEffect((): FunctionVoid => {
        if (!id) {
            dispatch(requestStreamDetails(callId));
        }

        return (): void => {
            dispatch(flushStreamDetails());
            dispatch(flushStreamError());
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callId, dispatch, match.path, streamLangSetting]);

    useEffect(() => {
        setViewersCount(initialViewersCount);
    }, [initialViewersCount]);

    useEffect(() => {
        const REPORT_TOPIC = 'answer_choices_153124';
        const PLAYER_ISSUE = '555909';

        dispatch(setParamsYandexBug({ [REPORT_TOPIC]: PLAYER_ISSUE }));

        return () => {
            dispatch(setParamsYandexBug({}));
        };
    }, [dispatch]);

    useLeaveHandlers({
        onTabLeave: () => dispatch(streamEndEvent({ id, endEvent: 'tabLeave' })),
        onTabReturn: () => dispatch(streamEndEvent({ id, endEvent: 'tabReturn' })),
        onPageLeave: () => dispatch(streamEndEvent({ id, endEvent: 'pageLeave' })),
        onTabClose: () => dispatch(streamEndEvent({ id, endEvent: 'tabClose' })),
    });

    const handleReceiveViewersNumber = useCallback((viewersCount: number) => {
        setViewersCount(viewersCount);
    }, []);

    const handleStreamLanguageChanged = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value as Language;

        if (value !== streamLangSetting) {
            setStreamLangSetting(value);

            const streamId = translatedStreams[value];

            dispatch(flushStreamDetails());
            dispatch(flushStreamError());
            dispatch(push(`/streams/${streamId}`));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translatedStreams, streamLangSetting]);

    // Определяем дату окончания трансляции
    // Если пришли данные о календарной встрече, то дата окончания трансляции равна дате окончания календарной встречи
    // Иначе дата окончания трансляции равна дате окончания звонка
    const streamStopTime = useMemo(() => event ? event.endTime : stopTime, [event, stopTime]);

    return (
        <>
            <ViewersSocket
                onReceiveViewersNumber={handleReceiveViewersNumber}
            />
            <StreamScreenView
                loading={isFirstMount || isRequesting}
                error={Boolean(responseError)}
                responseError={responseError}
                empty={!streamId}
                calendarEventUrl={calendarEventUrl.toString()}
                name={name}
                etherUrl={etherUrl}
                description={streamDescription}
                streamId={streamId}
                chatInviteHash={chatInviteHash}
                env={environment}
                eventId={eventId}
                retranslatorHost={retranslatorHost}
                navigateToChat={navigateToChat}
                type="ether"
                playerType={playerType}
                onPlayerTypeChange={handlePlayerTypeChange}
                platform={platform}
                viewersCount={viewersCount}
                uri={uri}
                stopTime={streamStopTime}
                translatedStreams={translatedStreams}
                lang={lang}
                onStreamLanguageChanged={handleStreamLanguageChanged}
            />
        </>
    );
};
