import { useEffect } from 'react';

export const useClickableLinks = ({
    text,
    ref,
}: {
    text: string,
    ref: React.RefObject<HTMLElement>
}) => {
    useEffect(() => {
        if (ref.current) {
            const words = text.split(' ');
            const wordsPatched = words.map(word => {
                let url: URL = null;

                try {
                    url = new URL(word);
                } catch (e) {}

                if (url) {
                    return `<a href="${url.href}">${word}</a>`;
                }

                return word;
            });

            ref.current.innerHTML = wordsPatched.join(' ');
        }
    }, [text, ref]);
};
