import { compose } from '@bem-react/core';
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import {
    selectStreamDetails,
    requestStreamDetailsPermanent,
    flushStreamError,
    streamEndEvent,
} from 'ducks/stream';

import { useLeaveHandlers } from 'hooks';
import { withLoading } from './_loading/PermanentLinkScreen_loading';
import { withError } from './_error/PermanentLinkScreen_error';
import {
    PermanentLinkScreenContainerProps,
    PermanentLinkScreenContainerMatchParams,
    STATE,
} from './PermanentLinkScreen.interface';
import { PermanentLinkScreen as PermanentLinkScreenBase } from './PermanentLinkScreen';
import {
    useRedirect,
    usePermanentLinkScreenState,
} from './PermanentLinkScreen.hooks';

const PermanentLinkScreenView = compose(
    withLoading,
    withError,
)(PermanentLinkScreenBase);

export const PermanentLinkScreen: FC<PermanentLinkScreenContainerProps> =
    (): ReactElement => {
        const dispatch = useDispatch();
        const { responseError, id, isRequesting } = useSelector(selectStreamDetails);

        const match = useRouteMatch<PermanentLinkScreenContainerMatchParams>();

        const templateId = match.params.id;
        const permanentLinkType = match.params.permanentLinkType;

        const state = usePermanentLinkScreenState(isRequesting, id, responseError);

        useRedirect(state, id, permanentLinkType);

        useEffect(() => {
            dispatch(requestStreamDetailsPermanent(templateId));

            return () => {
                dispatch(flushStreamError());
            };
        }, [dispatch, templateId]);

        useLeaveHandlers({
            onTabLeave: () => dispatch(streamEndEvent({ id, endEvent: 'tabLeave' })),
            onTabReturn: () => dispatch(streamEndEvent({ id, endEvent: 'tabReturn' })),
            onPageLeave: () => dispatch(streamEndEvent({ id, endEvent: 'pageLeave' })),
            onTabClose: () => dispatch(streamEndEvent({ id, endEvent: 'tabClose' })),
        });

        return (
            <PermanentLinkScreenView
                loading={state === STATE.LOADING}
                error={state === STATE.ERROR}
                responseError={responseError}
                event={state === STATE.DISPLAY_NEXT_EVENT && responseError?.errorDetails?.event}
                permanentLinkType={permanentLinkType}
            />
        );
    };
